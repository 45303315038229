import clsx from 'clsx';
import { Button, LeftOutlined, RightOutlined } from 'core-ui';
import { Views } from 'core-calendar';
import { useSelector } from 'react-redux';
import scheduleSlice from 'src/redux/scheduleSlice';
import { dayjs } from 'core-helpers';
import { useEffect } from 'react';
import {
  currentDate,
  currentDay,
  currentMonth,
  currentYear,
  onPrevNextDay
} from 'src/constants/datetime';
import { activeViews } from '..';
import { RootState } from '../ScheduleDay/CustomTimeLine';
import { useAppDispatch } from 'src/redux/hooks';

interface CustomToolbarViewDayProps {}

type listViewsType = {
  label: string;
  view?: string;
  typeView: string;
};
const listViews = [
  { label: '日', view: Views.DAY, typeView: 'day' },
  { label: '週', view: Views.WEEK, typeView: 'week' },
  { label: '月', view: Views.MONTH, typeView: 'month' }
];

const CustomToolbarViewDay: React.FC<CustomToolbarViewDayProps> = ({}) => {
  const dispatch = useAppDispatch();

  const { setStatusActionTabView, setActionView, setStartTime, setEndTime } =
    scheduleSlice.actions;

  const actionView = useSelector(
    (state: RootState) => state?.schedule?.actionView
  );

  const { startTime, endTime, statusActionTabView } = useSelector(
    (state: RootState) => state.schedule
  );

  const getMondayAndSundayOfCurrentWeek = () => {
    const dayOfWeek = currentDate.getDay();
    const daysToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
    const start = new Date(currentDate);
    start.setDate(currentDate.getDate() + daysToMonday);
    const end = new Date(start);
    end.setDate(start.getDate() + 6);
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 59);

    return { start, end };
  };

  const { start, end } = getMondayAndSundayOfCurrentWeek();

  const onPrevNext = (typeAction: string) => {
    const days = actionView.includes('day') ? 1 : 7;
    const dayOffset = typeAction.includes('prev') ? -days : days;

    dispatch(setStartTime(onPrevNextDay(startTime, dayOffset)));
    dispatch(setEndTime(onPrevNextDay(endTime, dayOffset)));
    dispatch(setStatusActionTabView(true));
  };

  useEffect(() => {
    const isDayView = actionView.includes('day');
    const startDate = isDayView
      ? new Date(currentYear, currentMonth, currentDay)
      : new Date(start);
    const endDate = isDayView
      ? new Date(currentYear, currentMonth, currentDay, 23, 59, 59)
      : new Date(end);

    if (!statusActionTabView) {
      dispatch(setStartTime(startDate));
      dispatch(setEndTime(endDate));
    }
  }, [actionView, statusActionTabView]);

  return (
    <>
      <div className="mx-2 mb-3 flex justify-between items-center">
        <div className="flex gap-2">
          <div
            className="bg-[#DDDDDD] rounded w-9 !h-9 flex items-center justify-center cursor-pointer"
            onClick={() => {
              onPrevNext('prev');
            }}
          >
            <LeftOutlined />
          </div>
          <div
            className="bg-[#DDDDDD] rounded w-9 !h-9 flex items-center justify-center cursor-pointer"
            onClick={() => {
              onPrevNext('day');
            }}
          >
            <RightOutlined />
          </div>
          <Button
            type="primary"
            className="!bg-[#4759FF] text-[#FFFFFF] h-9 !text-white cursor-pointer"
            disabled={actionView.includes('day')}
            onClick={() => {
              dispatch(setActionView(activeViews.Day));
              dispatch(setStatusActionTabView(false));
              dispatch(setStatusActionTabView(false));
            }}
          >
            今日
          </Button>
        </div>
        <div className="text-base font-medium ml-10">
          {dayjs(startTime).format('YYYY年MM月')}
        </div>
        <div className="flex gap-2">
          {listViews.map((element: listViewsType) => (
            <div
              className={clsx(
                `bg-[#DDDDDD] !text-white rounded w-9 !h-9 flex items-center justify-center cursor-pointer`,
                {
                  '!bg-[#4759FF]': actionView.includes(element.typeView)
                }
              )}
              onClick={() => {
                dispatch(setActionView(element.typeView));
                dispatch(setStatusActionTabView(false));
                dispatch(setStatusActionTabView(false));
              }}
            >
              {element?.label}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CustomToolbarViewDay;
