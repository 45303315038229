import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import 'moment/locale/ja';
import CustomToolbar from '../CustomToolBar';
import ContentEventCustom from './ContentEventCustom';
import styles from './style.module.scss';
import { CoreCalendar, EventPropGetter, momentLocalizer } from 'core-calendar';
import { ScheduleEventI } from 'src/models/schedule';
import {
  FORMAT,
  getStartEndDateOfMonth,
  getStartEndDateOfWeek
} from 'src/constants/datetime';
import { RADIO_COLOR } from 'src/constants/common';
import { useSelector } from 'react-redux';
import scheduleSlice from 'src/redux/scheduleSlice';
import { activeViews } from '..';
import { dayjs } from 'core-helpers';
import { RootState } from '../ScheduleDay/CustomTimeLine';
import { useAppDispatch } from 'src/redux/hooks';

const localizer = momentLocalizer(moment);

interface ScheduleMonthProps {
  showDrawEdit: () => void;
  listScheduleData: ScheduleEventI[];
}

const ScheduleMonth: React.FC<ScheduleMonthProps> = ({
  showDrawEdit,
  listScheduleData
}) => {
  const dispatch = useAppDispatch();

  const {
    setStatusActionTabView,
    setActionView,
    setStartTime,
    setEndTime,
    setDetailSchedule
  } = scheduleSlice.actions;

  const { startTime } = useSelector((state: RootState) => state.schedule);

  const [currentViewMonth, setCurrentViewMonth] = useState<Date>(startTime);

  useEffect(() => {
    const dateString = dayjs(currentViewMonth).format(FORMAT.MONTH_FORMAT);
    const date = dayjs(dateString, FORMAT.MONTH_FORMAT);
    const { startOfMonth, endOfMonth } = getStartEndDateOfMonth(date);
    dispatch(setStartTime(startOfMonth));
    dispatch(setEndTime(endOfMonth));
  }, [currentViewMonth, dispatch]);

  const handleNavigate = (date: Date) => {
    const dateString = new Date(date);
    setCurrentViewMonth(
      new Date(dateString.getFullYear(), dateString.getMonth())
    );
  };

  const eventPropGetter: EventPropGetter<ScheduleEventI> = useCallback(
    (event, start) => {
      let newProps: ReturnType<EventPropGetter<ScheduleEventI>> = {};
      let bgColorCustom = RADIO_COLOR.find(
        (item: { value?: number }) => item.value === Number(event?.color ?? 1)
      )?.bgColor;
      if (bgColorCustom) {
        newProps.style = {
          backgroundColor: bgColorCustom,
          marginTop: '5px',
          borderRadius: '8px',
          zIndex: 4444,
          position: 'relative'
        };
      }
      return newProps;
    },
    []
  );

  const handleShowMore = useCallback(
    (events: any, date: Date) => {
      const { start, end } = getStartEndDateOfWeek(date);
      dispatch(setStatusActionTabView(true));
      dispatch(setStartTime(start));
      dispatch(setEndTime(end));
      dispatch(setActionView(activeViews.Week));
    },
    [dispatch]
  );

  const handleSelectEvent = useCallback(
    (event: any) => {
      showDrawEdit();
      dispatch(setDetailSchedule(event));
    },
    // Only recreate the callback if any of these values change
    [dispatch]
  );

  return (
    <div className="bg-white mt-5 mx-5 pt-5 mb-8">
      <CoreCalendar
        views={['month']}
        selectable
        localizer={localizer}
        date={currentViewMonth}
        defaultView="month"
        events={listScheduleData}
        style={{ height: '950px' }}
        onSelectEvent={handleSelectEvent}
        onShowMore={handleShowMore}
        components={{
          toolbar: CustomToolbar,
          event: ContentEventCustom
        }}
        messages={{
          showMore: (total) => `他の${total}件`
        }}
        popup={false}
        eventPropGetter={eventPropGetter}
        className={styles.viewMonth}
        onNavigate={handleNavigate}
      />
    </div>
  );
};

export default ScheduleMonth;
